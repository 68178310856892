/** 16062022 - Gaurav - CA-1287: DataCacheService for all feature caches
 * 17062022 - Gaurav - Provide service at root
 * 20062022 - Gaurav - CA-1292: Added genMasters cache
 * 27062022 - Gaurav - CA-1289: Move MessageTemplates DrDw cache from Comm-AI service
 * 27062022 - Gaurav - CA-1290: Move Surveys DrDw cache
 * 27062022 - Gaurav - CA-1297: Copy resource feature (CA-1299 for UI)
 * 14092022 - Gaurav - CA-1486: Add month dropdown in header; added calMonths cache
 * 25012023 - Gaurav - CA-1692: Implement level 1 subMenuItems API changes
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export enum AppCacheName {
  infoFieldDimensions = 'infoFieldDimensions',
  holdingOrgInfoFields = 'holdingOrgInfoFields',
  messageTemplateDimensions = 'messageTemplateDimensions',

  scheduleHeaders = 'scheduleHeaders',
  scheduleDimensions = 'scheduleDimensions',
  scheduleLineSources = 'scheduleLineSource',
  scheduleLineDetailFields = 'scheduleLineDetailField',

  messagesTemplatesDrDw = 'messageTemplates',
  emailTemplatesDrDwCopyTemplates = 'emailTemplatesDrDwCopyTemplates',

  respSurveysDrDw = 'respSurveys',
  npsSurveysDrDw = 'npsSurveys',
  respSurveysDrDwCopyTemplates = 'respSurveysDrDwCopyTemplates',
  npsSurveysDrDwCopyTemplates = 'npsSurveysDrDwCopyTemplates',

  respSurveyComponents = 'respSurveyComponents',
  npsSurveyComponents = 'npsSurveyComponents',
  respSurveyComponentsDrDwCopyTemplates = 'respSurveyComponentsDrDwCopyTemplates',
  npsSurveyComponentsDrDwCopyTemplates = 'npsSurveyComponentsDrDwCopyTemplates',

  orgDimensions = 'orgDimensions',
  holdingOrgDrDwList = 'holdingOrgs', // must equal Resource.holdingOrgs
  holdingOrgsListForUserPage = 'holdingOrgsListForUserPage',
  allSysAdminHoldingOrgsList = 'allSysAdminHoldingOrgsList',
  allSysAdminMemberOrgsList = 'allSysAdminMemberOrgsList',
  genMasters = 'genMasters',
  currentUserDetails = 'currentUserDetails',
  calMonths = 'calMonths',

  contactItemCategories = 'contactItemCategories',
  dashConfigSubMenuItems = 'dashConfigSubMenuItems',
}

export interface AppCacheProps {
  appCacheName: AppCacheName | undefined;
  appCacheData: any;
}

@Injectable({ providedIn: 'root' })
export class DataCacheService {
  private appCache = new BehaviorSubject<AppCacheProps[]>([]);
  private cacheListToClearOnOrgChange = [
    AppCacheName.infoFieldDimensions,
    AppCacheName.holdingOrgInfoFields,
    AppCacheName.messageTemplateDimensions,

    AppCacheName.scheduleHeaders,
    AppCacheName.scheduleDimensions,
    AppCacheName.scheduleLineSources,
    AppCacheName.scheduleLineDetailFields,

    AppCacheName.messagesTemplatesDrDw,
    AppCacheName.emailTemplatesDrDwCopyTemplates,

    AppCacheName.respSurveysDrDw,
    AppCacheName.npsSurveysDrDw,
    AppCacheName.respSurveysDrDwCopyTemplates,
    AppCacheName.npsSurveysDrDwCopyTemplates,

    AppCacheName.respSurveyComponents,
    AppCacheName.npsSurveyComponents,
    AppCacheName.respSurveyComponentsDrDwCopyTemplates,
    AppCacheName.npsSurveyComponentsDrDwCopyTemplates,
    AppCacheName.calMonths,

    AppCacheName.contactItemCategories,
    AppCacheName.dashConfigSubMenuItems,
  ];

  setCache(props: AppCacheProps): void {
    const currentAppCaches: AppCacheProps[] = this.appCache.getValue();
    const foundPassedCacheIdx: number = currentAppCaches.findIndex(
      (cache) => cache.appCacheName === props.appCacheName
    );

    if (foundPassedCacheIdx > -1) {
      currentAppCaches[foundPassedCacheIdx] = props;
    } else {
      currentAppCaches.push(props);
    }

    console.log('DataCacheService : setCache', {
      props,
      currentAppCaches,
      foundPassedCacheIdx,
    });

    this.appCache.next([...currentAppCaches]);
  }

  getCache(cacheName: AppCacheName): Observable<AppCacheProps> {
    const foundCache = this.appCache
      ?.getValue()
      ?.find((cache) => cache.appCacheName === cacheName);

    console.log('DataCacheService : getCache', {
      cacheName,
      foundCache,
      currentAppCaches: this.appCache.getValue(),
    });

    return of(
      foundCache ?? {
        appCacheName: undefined,
        appCacheData: undefined,
      }
    );
  }

  clearCache(
    cacheName: AppCacheName,
    reasonNoOrInvalidApiResultData = false
  ): void {
    const currentAppCaches: AppCacheProps[] = this.appCache
      .getValue()
      .filter((cache) => cache.appCacheName !== cacheName);

    this.appCache.next([...currentAppCaches]);

    console.log('DataCacheService : clearCache', {
      cacheName,
      currentAppCaches,
      reasonNoOrInvalidApiResultData,
    });
  }

  clearCachesOnOrgChange(): void {
    this.clearCaches([...this.cacheListToClearOnOrgChange]);
  }

  clearCachesOnLogout(): void {
    // clear all caches
    this.appCache.next([]);
  }

  clearCaches(cacheNames: AppCacheName[]): void {
    const resetAppCaches: AppCacheProps[] = this.appCache
      .getValue()
      .filter(
        (cache) =>
          !cacheNames.includes(cache?.appCacheName ?? ('' as AppCacheName))
      );

    console.log('DataCacheService : clearCaches', {
      cacheNames,
      currentAppCaches: [...this.appCache.getValue()],
      resetAppCaches,
    });

    this.appCache.next([...resetAppCaches]);
  }
}
