import { Sort } from '@angular/material/sort';
import { RouterParentModule } from 'src/app/core/models/common.model';
import { environment } from '../../../environments/environment';
import { themClassList } from '../../dashboard/dashboard.service';
/**
 * Generates a string in the format of "{name} ({code})".
 * It will gracefully handle if name and/ or code are empty/ undefined
 * @param code
 * @param name
 */
export function generateNameAndCodeString(code: string, name: string): string {
  let nameAndCode = '';

  if (name && !code) {
    nameAndCode = name;
  } else if (name && code) {
    nameAndCode = `${name} (${code})`;
  } else if (!name && code) {
    nameAndCode = code;
  }

  return nameAndCode;
}

/** 29042021 - Gaurav - JIRA-CA-422: Dashboard configs code in Home component causes unexpected app behaviour. Validate url global function */
export function isValidHttpUrl(urlString: string) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

/** 07042021 - Gaurav - Returns the desired dots, to be used with setInterval (see usecase in stats-text-responses.component) */
export function dotdotdot(cursor: number, times: number, dot: string) {
  return Array(times - Math.abs((cursor % (times * 2)) - times) + 1).join(dot);
}

/** 10122020 - Gaurav - Central Console Log
 * Added allowConsoleLogs flag to control display of console logs from common.util.ts.
 * This shall prevent any accidental display of consoled information in prod mode */
export enum ConsoleTypes {
  log,
  error,
  warn,
  clear,
  table,
  count,
  dir,
  // time,
  // group
}

export interface ConsoleLogParams {
  consoleType?: ConsoleTypes;
  valuesArr?: any[];
}

/** Developers are encouraged to use this central method instead of arbitrarily calling console.logs  */
export function consoleLog({ consoleType, valuesArr }: ConsoleLogParams): void {
  if (environment?.allowConsoleLogs) {
    switch (consoleType) {
      case ConsoleTypes.error:
        console.error(...(valuesArr ?? ''));
        break;

      case ConsoleTypes.warn:
        console.warn(...(valuesArr ?? ''));
        break;

      case ConsoleTypes.clear:
        console.clear();
        break;

      case ConsoleTypes.table:
        console.table(...(valuesArr ?? ''));
        break;

      case ConsoleTypes.count:
        console.count(...(valuesArr ?? ''));
        break;

      case ConsoleTypes.dir:
        console.dir(...(valuesArr ?? ''));
        break;

      default:
        console.log(...(valuesArr ?? ''));
    }
  }
}
/** 10122020 - Gaurav - Central Console Log - Ends */

export function convertToRouteConfigPath(
  routePath: string,
  statePath: string
): string {
  if (!statePath || statePath === '') return '';
  let returnVal = statePath.replace('/dashboard/', '');
  /** 07122022 - Gaurav - CA-1606: Remove non-privilege paths (keywords that DO NOT appear in the user privileges array from logon API) */
  returnVal = returnVal.replace(`${RouterParentModule.customerData}/`, '');
  returnVal = returnVal.replace(`${RouterParentModule.engageAi}/`, '');

  if (!routePath || routePath === '') return returnVal;
  const colonPresent = routePath.indexOf(':') > -1;

  // console.log({ statePath }, { routePath }, { colonPresent });

  if (!colonPresent) return returnVal;

  const featureName = routePath.split('/')[0];
  const featureIndex = returnVal.indexOf(featureName);
  const modulePrefix = returnVal.slice(0, featureIndex);

  return `${modulePrefix}${routePath}`;
}

export const convertDynamicPathToPathDefinition = (
  dynamicPath: string,
  pathEntriesObject: any
): string | undefined | null => {
  dynamicPath = dynamicPath.replace('/dashboard/', '');
  /** 14042023 - Gaurav - CA-1729-fix: Added /add/ to find path */
  const findPath = [
    '/edit/',
    '/copy/',
    '/view/',
    '/add/',
    '/view-uploaded-file-data/',
  ];
  let foundIndex = -1;

  for (let i = 0; i < findPath.length; i++) {
    foundIndex = dynamicPath.indexOf(findPath[i]);
    if (foundIndex > -1) break;
  }

  const queryParamIndex = dynamicPath.indexOf('?');

  const path =
    foundIndex > -1
      ? dynamicPath.slice(0, foundIndex + 5)
      : queryParamIndex > -1
      ? dynamicPath.slice(0, queryParamIndex)
      : dynamicPath;

  const pathEntriesArray: any[] = Object.entries(pathEntriesObject);
  let pathDefn = undefined;

  try {
    pathDefn = pathEntriesArray.find((values: any) =>
      values[1].routerLink.includes(path)
    )?.[1]?.routerLink;
  } catch (error) {}

  return pathDefn;
};

export function isNumber(value: string | number | undefined): boolean {
  return (
    value != null &&
    value != undefined &&
    value !== '' &&
    !isNaN(Number(value.toString()))
  );
}

/** 01022022 - Gaurav - CA-1015: Customers > Sort not working */
export function getSortCriteria(
  pastSortCondition: string | undefined,
  sortEvent: Sort
): string {
  let sortArray =
    !!pastSortCondition && pastSortCondition !== ''
      ? pastSortCondition.split(',')
      : [];

  const removeCriteria1 = `${sortEvent.active} asc`;
  const removeCriteria2 = `${sortEvent.active} desc`;

  sortArray = sortArray?.some((r) => removeCriteria1)
    ? sortArray?.filter((r) => r !== removeCriteria1)
    : sortArray;
  sortArray = sortArray?.some((r) => removeCriteria2)
    ? sortArray?.filter((r) => r !== removeCriteria2)
    : sortArray;
  sortArray = sortArray?.some((r) => sortEvent.active)
    ? sortArray?.filter((r) => r !== sortEvent.active)
    : sortArray;

  if (sortEvent.direction === 'asc' || sortEvent.direction === 'desc') {
    sortArray.push(`${sortEvent.active} ${sortEvent.direction}`);
  }

  const sortCriteria =
    sortArray.length === 1
      ? sortArray.toString()
      : sortArray.length > 1
      ? sortArray.join(',')?.trim()
      : '';

  console.log('getSortCriteria', {
    pastSortCondition,
    active: sortEvent.active,
    direction: sortEvent.direction,
    sortArray,
    sortCriteria,
  });

  return sortCriteria;
}

export function isNotEmpty(msg: string): string | null {
  if (msg != null && msg != undefined && msg !== '') {
    return `Server error: ${msg}`;
  }

  return null;
}

/** 24022022 - Gaurav - Deep search object in a nested array */
export function deepSearch(object: any, key: string, filterFunction: any): any {
  if (object.hasOwnProperty(key) && filterFunction(key, object[key]) === true)
    return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]];
    if (typeof value === 'object' && value != null) {
      let o = deepSearch(object[Object.keys(object)[i]], key, filterFunction);
      if (o != null) return o;
    }
  }
  return null;
}

export const getDuplicateValuesFromArray = (
  values: any[],
  uniqueFieldName: string | null = null
) => {
  const objectArray = typeof values[0] === 'object';

  const countObj: { [key: string]: number } = values.reduce(
    (result, v) => ({
      ...result,
      [`${!!uniqueFieldName && !!objectArray ? v[uniqueFieldName] : v}`]:
        (result[!!uniqueFieldName && !!objectArray ? v[uniqueFieldName] : v] ||
          0) + 1,
    }),
    {}
  );

  return () => {
    return Object.keys(countObj).filter((a) => countObj[a] > 1);
  };
};

export const convertNumberToCommaSeparated = (value = 0) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const generateLightHslColor = () => {
  const color = 'hsl(' + (Math.random() * 360).toFixed(0) + ', 100%, 75%)';
  return color;
};

export const generateLightColorHex = () => {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += (
      '0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
    ).slice(-2);
  return color;
};

export const generateLightColorRgb = () => {
  const red = Math.floor(((1 + Math.random()) * 256) / 2);
  const green = Math.floor(((1 + Math.random()) * 256) / 2);
  const blue = Math.floor(((1 + Math.random()) * 256) / 2);
  return 'rgb(' + red + ', ' + green + ', ' + blue + ')';
};

export const generateRandomColorRgb = () => {
  const red = Math.floor(Math.random() * 256).toFixed(0);
  const green = Math.floor(Math.random() * 256).toFixed(0);
  const blue = Math.floor(Math.random() * 256).toFixed(0);
  return 'rgba(' + red + ', ' + green + ', ' + blue + ', 0.7)';
};

// 25112022 - Gaurav - CA-1611 fix: Download QR Code option is not working
export function openExternalLink(validHttpLink: string): void {
  if (!isValidHttpUrl(validHttpLink)) return;
  !!window && window.open(validHttpLink, '_blank');
}

export const convertToCamelCase = (text: string): string =>
  `${text.slice(0, 1).toLowerCase() + text.slice(1)}`;
