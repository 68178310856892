/** 11102022 - Gaurav - 11102022 - Gaurav - CA-1548: Move form validity observable from Auth Service to new Form Service */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface FormInvalidProps {
  isControlInvalid: boolean;
  isFormSubmit?: boolean;
}

export const kDefaultFormInvalidProps = <FormInvalidProps>{
  isControlInvalid: false,
  isFormSubmit: false,
};

export const kFocusFormInvalidProps = <FormInvalidProps>{
  isControlInvalid: true,
  isFormSubmit: true,
};

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _formInvalid = new Subject<FormInvalidProps>();
  public isFormInvalid = this._formInvalid.asObservable().pipe(delay(200));

  constructor() {}

  setFormInvalidity(props: FormInvalidProps): void {
    console.log('setFormInvalidity', { props });
    this._formInvalid.next(props);
  }
}
