<!-- <app-progress-bar [isLoading]="isLoading"></app-progress-bar> -->
<!-- 24112020 - Gaurav - The custome component for ProgressBarComponent did not work here, used the mat-progress-bar directly instead to focus on other priority items first -->
<!-- 10022021 - Abhishek - Added class 'flex-style' for forget form design issue. -->
<!-- 08072021 - Gaurav - CA-645: System Admin: Implement new button behaviour for missing required fields -->
<!-- 18072022 - Gaurav - CA-1336: Add background to login page -->
<mat-progress-bar
  mode="query"
  color="warn"
  class="height-1"
  *ngIf="isLoading"
></mat-progress-bar>

<div
  class="login-grand-container login-page"
  appFocusInvalidField
  [activateFocusInvalid]="isFormInvalid | async"
  style="--image-url: url('assets/img/login-bg/{{ getRandomImageNumber }}.jpg')"
>
  <div class="login-container">
    <div class="login-container-box">
      <app-reset-password *ngIf="isResetPasswordOnLogon"></app-reset-password>
      <mat-card
        appearance="outlined"
        [ngClass]="['mat-elevation-z5']"
        *ngIf="!isResetPasswordOnLogon"
      >
        <form
          #loginForm="ngForm"
          (ngSubmit)="onLogin(loginForm)"
          (click)="closePanel()"
          needs-validation
        >
          <div class="logo">
            <img src="assets/img/holding_org.png" alt="Holding Org Logo" />
          </div>
          <mat-divider class="logo-divider"></mat-divider>

          <div
            class="my-2 form-control-container {{
              !!usernameInput.invalid ? 'ng-invalid' : ''
            }}"
            [appHighlightFormField]="isFormInvalid | async"
            [isCurrentFieldValueValid]="!usernameInput.invalid"
          >
            <label for="username" class="form-label">Username or Email</label>
            <input
              type="text"
              class="form-control {{
                !!usernameInput.dirty && !!usernameInput.invalid
                  ? 'is-invalid'
                  : ''
              }}"
              id="username"
              name="username"
              ngModel
              #usernameInput="ngModel"
              required
              [disabled]="forgotPanelOpenState || isLoading"
              appFormControlInvalid
            />
            <div class="invalid-feedback">
              Please enter a valid username or email.
            </div>
          </div>

          <div
            class="my-2 form-control-container {{
              !!passwordInput.invalid ? 'ng-invalid' : ''
            }}"
            [appHighlightFormField]="isFormInvalid | async"
            [isCurrentFieldValueValid]="!passwordInput.invalid"
          >
            <label for="password" class="form-label">Password</label>
            <div class="input-group">
              <input
                [type]="hide ? 'password' : 'text'"
                class="form-control {{
                  !!passwordInput.dirty && !!passwordInput.invalid
                    ? 'is-invalid'
                    : ''
                }}"
                id="password"
                name="password"
                ngModel
                #passwordInput="ngModel"
                required
                [disabled]="forgotPanelOpenState || isLoading"
                appFormControlInvalid
              />
              <span class="input-group-text" (click)="hide = !hide">
                <i
                  class="fa-solid {{ hide ? 'fa-eye-slash' : 'fa-eye' }}"
                  style="cursor: pointer"
                ></i>
              </span>
              <div class="invalid-feedback">Please enter a valid password.</div>
            </div>
          </div>

          <div class="submit-btn">
            <mat-divider></mat-divider>

            <button
              class="default-button"
              mat-raised-button
              type="submit"
              [disabled]="
                forgotPanelOpenState || isLoading || isDisableLoginSubmitButton
              "
            >
              Login
              <ng-container *ngIf="isLoading"
                ><i class="fa fa-spinner fa-spin"></i
              ></ng-container>
            </button>
          </div>
        </form>
        <div class="forgot-container">
          <mat-expansion-panel
            hideToggle
            [ngClass]="['mat-elevation-z0']"
            mat-ex
            (opened)="forgotPanelOpenState = true"
            (closed)="forgotPanelOpenState = false"
          >
            <mat-expansion-panel-header class="forgot-header">
              {{
                forgotPanelOpenState
                  ? "Please submit your registered e-mail to get help"
                  : "Forgot Username / Password"
              }}
              <!-- <mat-panel-description>
                Forgot Username / Password
              </mat-panel-description> -->
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <!-- 10022021 - Abhishek - Added class 'flex-style' for forget form design issue. -->
                <form
                  class="forgot-form d-flex flex-style"
                  #forgotForm="ngForm"
                  (ngSubmit)="onForgotCredentials(forgotForm, loginForm)"
                >
                  <mat-form-field
                    [appHighlightFormField]="isFormInvalid | async"
                    [isCurrentFieldValueValid]="!forgotInput.invalid"
                  >
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      type="text"
                      name="email"
                      ngModel
                      #forgotInput="ngModel"
                      required
                      email
                      [disabled]="!forgotPanelOpenState || isLoading"
                      appFormControlInvalid
                    />
                    <mat-error *ngIf="forgotInput.invalid">
                      Please enter a valid email.
                    </mat-error>
                  </mat-form-field>
                  <button
                    mat-button
                    class="forgot-btn"
                    color="accent"
                    aria-label="Forgot Button"
                    type="submit"
                    [disabled]="
                      !forgotPanelOpenState ||
                      isLoading ||
                      isDisableForgotSubmitButton
                    "
                  >
                    Submit
                  </button>
                </form>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
      </mat-card>
    </div>
  </div>

  <p
    class="text-muted fixed-bottom"
    style="font-size: 0.7rem; margin-left: 5px; opacity: 0.5"
  >
    {{ getFreepikAttributionText }}
  </p>
</div>
