/** 02072021 - Gaurav - CA-638: Implement new button behaviour and required fields display: create base components
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 * 11102022 - Gaurav - Set y-axis padding to 0px for invalid control to avoid the control to shift-down below from the x-axis (in-line) with other controls
 */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { kDefaultFormInvalidProps } from 'src/app/core/models/app-constants';
import { FormInvalidProps } from 'src/app/core/models/auth';
import { FormService } from '../services/form.service';

@Directive({
  selector: '[appFocusInvalidField]',
})
export class FocusInvalidFieldDirective implements OnChanges {
  @Input() activateFocusInvalid?: FormInvalidProps | null =
    kDefaultFormInvalidProps;

  constructor(
    private _el: ElementRef,
    private _r2: Renderer2,
    private _formService: FormService
  ) {}

  // @HostListener('submit')
  // onFormSubmit() {
  //   console.log('appFocusInvalidField submit');
  //   this._setFocus();
  // }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(
      'FocusInvalidFieldDirective',
      { changes },
      { activateFocusInvalid: this.activateFocusInvalid }
    );

    if (!!this.activateFocusInvalid?.isControlInvalid) {
      this._setFocus();
    }
  }

  private _setFocus(): void {
    /** 18052022 - Gaurav - CA-1217
     * Earlier whenever a form control was invalid, regardless of its position in the form,
     * 1. we used to search all form controls that are "invalid", and
     * 2. would force set the focus onto the first found form control to be catered first!
     * This happened to highjack/move user current form field cursor position to that to the first "invalid" form control, resulting in weird user experience.
     *
     * Now, with the new change, the "invalid" form controls are searched in the following order as,
     * is the form-field -
     * 1. "invalid" and "touched",
     * 2. "invalid" and "dirty", OR
     * 3. just "invalid"
     *
     * 1. The first found field is given the required field highlight/makeover, and
     * 2. the focus would only be set to that found "invalid" form control if user has submitted the form
     * */

    let invalidMatFormField =
      this._el.nativeElement.querySelector(
        '.form-control-container.ng-invalid'
      ) ??
      this._el.nativeElement.querySelector(
        'mat-form-field.ng-invalid.ng-touched'
      ) ??
      this._el.nativeElement.querySelector(
        'mat-form-field.ng-invalid.ng-dirty'
      ) ??
      this._el.nativeElement.querySelector('mat-form-field.ng-invalid');

    const isFormSubmit = !!this.activateFocusInvalid?.isFormSubmit;

    console.log('FocusInvalidFieldDirective : _setFocus', {
      native: this._el.nativeElement,
      invalidMatFormField,
      isFormSubmit,
    });

    if (!invalidMatFormField && isFormSubmit) {
      const formControlContainers = Array.from(
        this._el.nativeElement.getElementsByClassName(
          'form-control-container'
        ) as HTMLCollection
      );

      formControlContainers.forEach((el: any, index: number) => {
        const hasInvalidEl = !!el?.querySelector('.ng-invalid');

        if (!!hasInvalidEl && !invalidMatFormField) {
          invalidMatFormField = el;
        }
      });

      // console.log({ formControlContainers });
    }

    if (!invalidMatFormField) {
      this._formService.setFormInvalidity(kDefaultFormInvalidProps);
      return;
    }

    this._r2.setStyle(invalidMatFormField, 'border-left', '5px solid red');
    this._r2.setStyle(
      invalidMatFormField,
      'background-color',
      'rgb(220 141 141 / 17%)'
    );
    this._r2.setStyle(invalidMatFormField, 'padding', '0px 10px');
    this._r2.setStyle(invalidMatFormField, 'border-radius', '5px');

    if (!!this.activateFocusInvalid?.isFormSubmit) {
      let inputEl =
        invalidMatFormField?.querySelector('mat-select') ??
        invalidMatFormField?.querySelector('select');
      const isMatSelectInput = !!inputEl;

      !inputEl && (inputEl = invalidMatFormField?.querySelector('input'));
      !inputEl && (inputEl = invalidMatFormField?.querySelector('textarea'));
      const isMatInput = !!inputEl;

      if (inputEl) {
        console.log({ inputEl });
        isMatSelectInput && inputEl?.click();
        isMatInput && inputEl?.focus();
        //     // setTimeout(() => {
        //     //   // this._el.nativeElement?.click();
        //     //   inputEl?.blur();
        //     //   // inputEl?.close();
        //     // }, 500);
        //   }
      }

      console.log('FocusInvalidFieldDirective : _setFocus : isFormSubmit', {
        isMatSelectInput,
      });

      this._resetToDefaultProps(1000);
    } else {
      /** 18052022 - Gaurav - CA-1217: Reset "invalid" state after the highlight and focus work is done */
      this._resetToDefaultProps(500);
    }
  }

  private _resetToDefaultProps(timerSeconds: number): void {
    setTimeout(() => {
      this._formService.setFormInvalidity(kDefaultFormInvalidProps);
      return;
    }, timerSeconds);
  }
}
