import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/core/external/error-state-macher';
import { PasswordValidator } from 'src/app/core/models/password.validate';
import { SnackbarService } from 'src/app/shared/components/snackbar.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  hide = true;
  isLoading: boolean = false;
  matcher = new MyErrorStateMatcher();
  isSubmitButtonDisabled: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    public _router: Router,
    public _snackbarService: SnackbarService
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), PasswordValidator.strong]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords });
  }
  ngOnInit(): void {
  }
  changePassword(resetPasswordForm: UntypedFormGroup) {
    if (!resetPasswordForm.valid) return;
    const payload = { password: resetPasswordForm.controls.password.value }
    this._authService.resetPassword(payload).then((response) => {
      this.isSubmitButtonDisabled = true;
      this._authService.isResetPasswordOnLogon = false;
      this._snackbarService.showSuccess(response?.message ?? 'Password changed successfully', 5000);
      this._authService.logout();
      this._router.navigate(['/login']);

    })
      .catch(error => {
        this._snackbarService.showError(error.message)
      })

  }
  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }
}
