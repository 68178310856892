import { EntityStatusCode } from '../dto/base.dto.ts';
import { HoldingOrgDataBinding } from './auth';
import { BaseInterFace } from './baseInterface';
import { OrgType } from './features.model';

export const kCanUseGlobalOrgSelectorPrivilege = 'CanUseGlobalOrgSelector';
export const kCentriqeAdminPrivilege = 'CENTRIQE_ADMIN';

export enum UserType {
  SystemAdmin = 'SystemAdmin',
  CentriqeAdmin = 'CentriqeAdmin',
  CentriqeUser = 'CentriqeUser',
  ClientAdmin = 'ClientAdmin',
  ClientUser = 'ClientUser',
}

export function getUserTypeDisplayValue(utc: UserType): string | undefined {
  if (!utc) return undefined;

  const dv = {
    [UserType.SystemAdmin]: 'System Admin',
    [UserType.CentriqeAdmin]: 'Centriqe Admin',
    [UserType.CentriqeUser]: 'Centriqe User',
    [UserType.ClientAdmin]: 'Client Admin',
    [UserType.ClientUser]: 'Client User',
  };

  return dv[utc];
}

export interface IUser extends BaseInterFace {
  id: string;
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  title: string;
  roles: Array<any>;
  resetPasswordNextLogon?: boolean;
}

export interface OrgAccessConfigList {
  holdingOrgId: string;
  holdingOrgCode: string;
  holdingOrgName: string;
  memberOrgId?: string;
  memberOrgCode?: string;
  memberOrgName?: string;
  privilegeCodes: string[];
  subscribedModules: string[];
}

//UI internal use ONLY
export interface OrgAccessConfigControl extends OrgAccessConfigList {
  orgType: OrgType;
}

export interface UserTypeListProp {
  displayValue: String;
  systemValue: UserType;
  displayIcon: String;
}

export interface UserListRecord {
  createdBy: string;
  createdDt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  resetPasswordNextLogon: boolean;
  status: EntityStatusCode;
  title: string;
  updatedBy: string;
  updatedDt: string;
  userName: string;
  userType: UserType;
}

export interface UserProfile extends UserListRecord {
  orgAccessList: OrgAccessConfigList[];
  privilegeCodes: string[];
  password?: string;
}

export interface HoldingOrgForUserPageStruct {
  code: string;
  dataBindings: HoldingOrgDataBinding[];
  id: string;
  memberOrgs: {
    id: string;
    code: string;
    name: string;
    subscribedModules: string[];
    selected: boolean;
  }[];
  name: string;
  subscribedModules: string[];
}
