/** 18042022 - Gaurav - CA-1161: Remove AskBuddy menu item
 * 26042022 - Gaurav - CA-1160: Search endpoint structure - used queryBySearch outside options per new API structure
 * 26052022 - Gaurav - CA-1237: Implement list screen for Observations
 * 20072022 - Gaurav - CA-1340: Add Vision Edge menu structure
 * 20072022 - Gaurav - CA-1341: Fix "Profit Edge" name in dashboard config
 * 20072022 - Gaurav - CA-1342: Add Vision Edge to Dashboard Config
 * 20072022 - Gaurav - CA-1351: Dashboard Config > Move Vision Edge after Profit Edge
 * 20072022 - Gaurav - CA-1349: Add Vision Edge to list of subscriptions in org config
 * 20072022 - Gaurav - CA-1350: Add Vision Edge privileges to user config
 * 21092022 - Gaurav - CA-1501: Prototype for 'onboarding steps'
 * 07122022 - Gaurav - Redesign Response AI menu
 * 14122022 - Gaurav - CA-1644: Rename SR to Orders and move to its own header menu (like home, no drop-down)
 * 14122022 - Gaurav - CA-1631: Add Orders as new org subscription module based on srq privilege
 * 13012023 - Gaurav - Removed hard-coded dynamic menus from privilegeMenuMapping
 * 19012023 - Gaurav - CA-1690 fix: Dashboard Configuration -> Import option doesn't show submenu data for NPS
 * 25012023 - Gaurav - CA-1692: removed hard-coded subMenuItems list
 * 03022023 - Gaurav - CA-1693: Implement level 2 menuItems API changes replacing all old dashconfig APIs and allowing generic entries
 */

import { EntityStatusCode } from '../dto/base.dto.ts';
import { OrgType } from './features.model';

export type AOA = any[][];
export type SelectDisplayView = 'table' | 'chart' | 'card';

export interface DimensionBase {
  id: string;
  status: EntityStatusCode;
  shortName: string;
  description?: string;
}

export enum MenuActionType {
  add = 'add',
  edit = 'edit',
  view = 'view',
  viewAll = 'viewAll',
}

export interface ApiParams {
  orgType?: OrgType;
  orgId?: string;
  dashboardConfigItemId?: string;
}

export enum OrgTypeCode {
  HoldingOrg = 'HoldingOrg',
  MemberOrg = 'MemberOrg',
}

export enum MessageChannel {
  WhatsApp = 'WhatsApp',
  Sms = 'Sms',
  Email = 'Email',
}

export type HtmlButtonType = 'submit' | 'reset' | 'button';

export enum FormControlStatusEnum {
  VALID = 'VALID',
  INVALID = 'INVALID',
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
}

/** 25012022 - Gaurav - CA-986: Campaign > Launched Campaigns - Updated structure */
export interface QueryBySearch {
  fieldNames: string[];
  searchValue: string;
}

export interface QueryByCriteria {
  campaignId?: string;
  filterCriteria?: SearchCriteria[];
}

export interface SearchByCriteriaPostData {
  options: {
    offset: number;
    limit: number;
    sort?: string;
  };
  queryBySearch?: QueryBySearch;
  queryByCriteria?: QueryByCriteria;
}

// 28072021 - Ramesh - New data domain enum */
export interface QueryByCriteriaPostData extends SearchByCriteriaPostData {
  queryBySearch?: QueryBySearch;
}

export interface SearchCriteria {
  rowNumber: number;
  startParenthesisCount: number;
  endParenthesisCount: number;
  attributeName: string;
  operator: MongoDbOperator;
  values: string[];
  logicalConcatenation?: MongoDbQueryOperator | null;
}

export enum MongoDbOperator {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Greater = 'Greater',
  GreaterOrEqual = 'GreaterOrEqual',
  Lower = 'Lower',
  LowerOrEqual = 'LowerOrEqual',
  InList = 'InList',
  NotInList = 'NotInList',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  DaysBefore = 'DaysBefore',
  DaysAfter = 'DaysAfter',
  DaysBeforeNoYear = 'DaysBeforeNoYear',
  DaysAfterNoYear = 'DaysAfterNoYear',
  IsPopulated = 'IsPopulated',
  IsNotPopulated = 'IsNotPopulated',
}

export enum MongoDbQueryOperator {
  And = 'And',
  Or = 'Or',
}

export interface UniqueValues {
  attributeName: string;
  values: string[] | number[] | boolean[];
}

export enum AttributeDataProviderType {
  Enum = 'Enum',
  Dynamic = 'Dynamic',
  None = 'None',
}

export enum AttributeDataType {
  // From backend
  String = 'String',
  Number = 'Number',
  Date = 'Date',
  StringMonthDay = 'StringMonthDay',

  // Used in UI
  Integer = 'Integer',
  Text = 'Text',
  NumberDate = 'NumberDate',
  Multiple = 'Multiple',
  StringInput = 'StringInput',
  NumberEnum = 'NumberEnum',
  StringEnum = 'StringEnum', // 26012022 - Gaurav - CA-982: Campaign > Attribute values
  DateTime = 'DateTime',
  DateOnly = 'DateOnly',
}

export enum Weekdays {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum AccessModes {
  PreAdd = 'PreAdd',
  Add = 'Add',
  Edit = 'Edit',
  EditAll = 'EditAll',
  Copy = 'Copy',
  View = 'View',
  ViewAll = 'ViewAll',
  Manage = 'Manage',
}

/** An enum of dash menus to avoid typing-errors */
export enum DashboardMenuEnum {
  HOME = 'HOME',
  // ASK_BUDDY = 'ASK_BUDDY',
  COMM_AI = 'COMM_AI',
  RESP_AI = 'RESP_AI',
  NPS = 'NPS',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  MARKET_AI = 'MARKET_AI',
  INSIGHT = 'INSIGHT',
  PROF_EDGE = 'PROF_EDGE',
  VISION_EDGE = 'VISION_EDGE',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CENTRIQE_ADMIN = 'CENTRIQE_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  SRQ = 'SRQ',
}

export function getDashboardMenuDisplayValue(
  d: DashboardMenuEnum
): string | undefined {
  if (!d) return undefined;
  const displayValue = {
    [DashboardMenuEnum.HOME]: 'Home',
    // [DashboardMenuEnum.ASK_BUDDY]: 'Ask Buddy',
    [DashboardMenuEnum.COMM_AI]: 'Comm AI',
    [DashboardMenuEnum.RESP_AI]: 'Resp AI',
    [DashboardMenuEnum.NPS]: 'NPS',
    [DashboardMenuEnum.MARKET_AI]: 'Market Place AI',
    [DashboardMenuEnum.PROF_EDGE]: 'Profit Edge',
    [DashboardMenuEnum.INSIGHT]: 'Insight',
    [DashboardMenuEnum.CLIENT_ADMIN]: 'Admin',
    [DashboardMenuEnum.CENTRIQE_ADMIN]: 'Org Admin',
    [DashboardMenuEnum.SYSTEM_ADMIN]: 'System',
    [DashboardMenuEnum.VISION_EDGE]: 'Vision Edge',
    [DashboardMenuEnum.SRQ]: 'Orders',
    [DashboardMenuEnum.SOCIAL_MEDIA]: 'Social Media',
  };

  return displayValue[d];
}

export enum MenuAccessEnum {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  ANALYTICS = 'ANALYTICS',
}

export enum RouterParentModule {
  home = 'home',
  askBuddy = 'askBuddy',
  communicationAI = 'communicationAI',
  responseAI = 'responseAI',
  nps = 'nps',
  marketAI = 'marketAI',
  profitEdge = 'profitEdge',
  insight = 'insight',
  visionEdge = 'visionEdge',
  clientAdmin = 'client-admin',
  centriqeAdmin = 'centriqeAdmin',
  sysAdmin = 'sysAdmin',
  userSettings = 'user-settings',
  onboarding = 'onboarding',
  srq = 'orders',
  customerData = 'customer-data',
  engageAi = 'engage-ai',
  socialMedia = 'social-media',
}

/** 15122020 - Gaurav - Added for uniformity and to prevent any inadvertent typing mistakes when used
 * 28072021 - Gaurav - CADN-119: New data domain enum */
export enum DataDomainConfig {
  Customer = 'Customer',
  Product = 'Product',
  Revenue = 'Revenue',
  Cost = 'Cost',
  Comm = 'Comm',
  Resp = 'Resp',
  Nps = 'Nps',
  ProfitEdge = 'ProfitEdge',
  MarketPlace = 'MarketPlace',
  VisionEdge = 'VisionEdge',
  System = 'System', //not used on ui
}

export enum DataDomainGroupCode {
  System = 'System',
  AppModules = 'AppModules',
  Customer = 'Customer',
  Product = 'Product',
  Revenue = 'Revenue',
  Cost = 'Cost',
}

export enum DataDomainMode {
  Single = 'Single',
  Multi = 'Multi',
}

export function getDataDomainGroupDetails(ddgc: DataDomainGroupCode): {
  key: string;
  mode: DataDomainMode;
  displayValue: string;
} {
  const rv: any = {
    [DataDomainGroupCode.AppModules]: {
      key: 'appModules',
      mode: DataDomainMode.Multi,
      displayValue: 'Application Modules',
    },
    [DataDomainGroupCode.Customer]: {
      key: 'customer',
      mode: DataDomainMode.Single,
      displayValue: DataDomainGroupCode.Customer,
    },
    [DataDomainGroupCode.Product]: {
      key: 'product',
      mode: DataDomainMode.Single,
      displayValue: DataDomainGroupCode.Product,
    },
    [DataDomainGroupCode.Revenue]: {
      key: 'revenue',
      mode: DataDomainMode.Single,
      displayValue: DataDomainGroupCode.Revenue,
    },
    [DataDomainGroupCode.Cost]: {
      key: 'cost',
      mode: DataDomainMode.Multi,
      displayValue: DataDomainGroupCode.Cost,
    },
  };
  return rv[ddgc];
}

/** 07052021 - Gaurav - JIRA-CA-481: Mark privileges modules read-only depending on org data */
export enum SubscribedModuleCodesEnum {
  AskBuddy = 'AskBuddy',
  Comm = 'Comm',
  Insight = 'Insight',
  MarketPlace = 'MarketPlace',
  Nps = 'Nps',
  Srq = 'Srq',
  ProfitEdge = 'ProfitEdge',
  Resp = 'Resp',
  VisionEdge = 'VisionEdge',
}

export function getSubscribedModuleDisplayValue(
  sm: SubscribedModuleCodesEnum
): string | undefined {
  if (!sm) return undefined;
  const displayValue = {
    [SubscribedModuleCodesEnum.AskBuddy]: 'Ask Buddy',
    [SubscribedModuleCodesEnum.Comm]: 'Communication AI',
    [SubscribedModuleCodesEnum.Insight]: 'Insight',
    [SubscribedModuleCodesEnum.MarketPlace]: 'Market Place AI',
    [SubscribedModuleCodesEnum.Nps]: 'NPS',
    [SubscribedModuleCodesEnum.Srq]: 'Orders',
    [SubscribedModuleCodesEnum.ProfitEdge]: 'Profit Edge',
    [SubscribedModuleCodesEnum.Resp]: 'Response AI',
    [SubscribedModuleCodesEnum.VisionEdge]: 'Vision Edge',
  };

  return displayValue[sm];
}

/** 20211006 - Frank - Added for survey adhoc code display message.
 * Maybe this exists already somewhere else.
 */
export function getSubscribedModuleDisplayValueByDataDomain(
  sm?: DataDomainConfig
): string | undefined {
  if (!sm) return undefined;
  const displayValue = {
    [DataDomainConfig.Comm]: 'Communication AI',
    [DataDomainConfig.MarketPlace]: 'Market Place AI',
    [DataDomainConfig.Nps]: 'NPS',
    [DataDomainConfig.ProfitEdge]: 'Profit Edge',
    [DataDomainConfig.Resp]: 'Response AI',
    [DataDomainConfig.Customer]: 'Customer',
    [DataDomainConfig.Product]: 'Product',
    [DataDomainConfig.Revenue]: 'Revenue',
    [DataDomainConfig.Cost]: 'Cost',
    [DataDomainConfig.System]: 'System',
    [DataDomainConfig.VisionEdge]: 'Vision Edge',
  };

  return displayValue[sm];
}

/** 05082021 - Gaurav - CADN-130: Review inactivate actions */
export interface StatusUpdate {
  status: EntityStatusCode; //integer
}

export enum OrgAccessDisplayItemIdentifier {
  holdingOrg = 'holdingOrg',
  memberOrg = 'memberOrg',
}

/** 20082021 - Gaurav - CA-704: Popup and Button tooltips: add delay to show and hide */
export const kPopupTooltipShowDelay = 1000;
export const kPopupTooltipHideDelay = 500;
export const kButtonTooltipShowDelay = 1000;
export const kButtonTooltipHideDelay = 500;

export interface SystemDisplayValuePair {
  systemValue: any;
  displayValue: any;
}

export interface OnCancelPayload {
  dirty: boolean;
}

export interface GetAllApiResponseStruct {
  info?: ApiInfo;
  results: any[];
}

interface ApiInfo {
  limit: number;
  offset: number;
  queryTime: number;
  resultCount: number;
  totalCount: number;
}

// export enum RecordStatus {
//   Active = <any>'Active',
//   Inactive = <any>'Inactive',
// }

export enum AppMainMenu {
  Home = 'Home',
  AskBuddy = 'AskBuddy',
  Comm = 'Comm',
  Resp = 'Resp',
  Nps = 'Nps',
  MarketPlace = 'MarketPlace',
  Insight = 'Insight',
  ProfitEdge = 'ProfitEdge',
  VisionEdge = 'VisionEdge',
  ClientAdmin = 'ClientAdmin',
  CentriqeAdmin = 'CentriqeAdmin',
  SystemAdmin = 'SystemAdmin',
  SRQ = 'SRQ',
  // Entries below are NOT attached to any privileges
  CustomerData = 'CustomerData',
  EngageAI = 'EngageAI',
  SocialMedia = 'SocialMedia',
}

/** 03022022 - Gaurav - CA-1020: Add lvl3 menus for admin menu options. Updated DynamicSubMenuName & PrivilegeMenuMapping */
export enum DynamicSubMenuName {
  Analytics = 'Analytics',
  Customer = 'Customer',
  Product = 'Product',
  Revenue = 'Revenue',
  GrossProfit = 'GrossProfit',
  Cost = 'Cost',
  Inventory = 'Inventory',
  RevenueMaximizer = 'RevenueMaximizer',
  GrossProfitability = 'GrossProfitability',
  CostOptimizer = 'CostOptimizer',
  NetProfitability = 'NetProfitability',
  SplitMenu = 'SplitMenu',
  Home = 'Home',
  View = 'View',
}

export function getAppMenuDisplayName(name: AppMainMenu): string {
  const displayName = {
    [AppMainMenu.Home]: 'Home',
    [AppMainMenu.AskBuddy]: 'Ask Buddy',
    [AppMainMenu.Comm]: 'Comm AI',
    [AppMainMenu.Resp]: 'Resp AI',
    [AppMainMenu.Nps]: 'NPS',
    [AppMainMenu.MarketPlace]: 'Market Place AI',
    [AppMainMenu.Insight]: 'Insight',
    [AppMainMenu.ProfitEdge]: 'Profit Edge',
    [AppMainMenu.ClientAdmin]: 'Admin',
    [AppMainMenu.CentriqeAdmin]: 'Org Admin',
    [AppMainMenu.SystemAdmin]: 'System',
    [AppMainMenu.VisionEdge]: 'Vision Edge',
    [AppMainMenu.SRQ]: 'Orders',
    [AppMainMenu.CustomerData]: 'Data',
    [AppMainMenu.EngageAI]: 'Engage',
    [AppMainMenu.SocialMedia]: 'Social Media',
  };

  return displayName[name];
}

export function getSubMenuDisplayName(name: DynamicSubMenuName): string {
  const displayName = {
    [DynamicSubMenuName.Analytics]: 'Analytics',
    [DynamicSubMenuName.Customer]: 'Customer',
    [DynamicSubMenuName.Product]: 'Product',
    [DynamicSubMenuName.Revenue]: 'Revenue',
    [DynamicSubMenuName.GrossProfit]: 'Gross Profit',
    [DynamicSubMenuName.Cost]: 'Cost',
    [DynamicSubMenuName.Inventory]: 'Inventory',
    [DynamicSubMenuName.RevenueMaximizer]: 'Revenue Maximizer',
    [DynamicSubMenuName.GrossProfitability]: 'Gross Profitability',
    [DynamicSubMenuName.CostOptimizer]: 'Cost Optimizer',
    [DynamicSubMenuName.NetProfitability]: 'Net Profitability',
    [DynamicSubMenuName.SplitMenu]: 'Split Menu',
    [DynamicSubMenuName.Home]: 'Home',
    [DynamicSubMenuName.View]: 'View',
  };

  return displayName[name];
}

export interface PrivilegeMenuMapping {
  menuPosition: number;
  mainMenuCode: AppMainMenu;
  mainMenuIcon: string;
  routerParentModule: RouterParentModule;
  dynamicSubMenus: DynamicSubMenu[];
}

export interface DynamicSubMenu {
  subMenuPosition: number;
  systemName: DynamicSubMenuName;
  displayName: string;
  subMenuIcon: string;
}

export const privilegeMenuMapping = Object.freeze({
  [DashboardMenuEnum.HOME]: <PrivilegeMenuMapping>{
    menuPosition: 0,
    mainMenuCode: AppMainMenu.Home,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.home,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.COMM_AI]: <PrivilegeMenuMapping>{
    menuPosition: 3,
    mainMenuCode: AppMainMenu.Comm,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.communicationAI,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.RESP_AI]: <PrivilegeMenuMapping>{
    menuPosition: 4,
    mainMenuCode: AppMainMenu.Resp,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.responseAI,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.NPS]: <PrivilegeMenuMapping>{
    menuPosition: 5,
    mainMenuCode: AppMainMenu.Nps,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.nps,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.SOCIAL_MEDIA]: <PrivilegeMenuMapping>{
    menuPosition: 6,
    mainMenuCode: AppMainMenu.SocialMedia,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.socialMedia,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.SRQ]: <PrivilegeMenuMapping>{
    menuPosition: 7,
    mainMenuCode: AppMainMenu.SRQ,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.srq,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.MARKET_AI]: <PrivilegeMenuMapping>{
    menuPosition: 8,
    mainMenuCode: AppMainMenu.MarketPlace,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.marketAI,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.INSIGHT]: <PrivilegeMenuMapping>{
    menuPosition: 9,
    mainMenuCode: AppMainMenu.Insight,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.insight,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.PROF_EDGE]: <PrivilegeMenuMapping>{
    menuPosition: 10,
    mainMenuCode: AppMainMenu.ProfitEdge,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.profitEdge,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.VISION_EDGE]: <PrivilegeMenuMapping>{
    menuPosition: 11,
    mainMenuCode: AppMainMenu.VisionEdge,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.visionEdge,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.CLIENT_ADMIN]: <PrivilegeMenuMapping>{
    menuPosition: 12,
    mainMenuCode: AppMainMenu.ClientAdmin,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.clientAdmin,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.CENTRIQE_ADMIN]: <PrivilegeMenuMapping>{
    menuPosition: 13,
    mainMenuCode: AppMainMenu.CentriqeAdmin,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.centriqeAdmin,
    dynamicSubMenus: [],
  },
  [DashboardMenuEnum.SYSTEM_ADMIN]: <PrivilegeMenuMapping>{
    menuPosition: 14,
    mainMenuCode: AppMainMenu.SystemAdmin,
    mainMenuIcon: '',
    routerParentModule: RouterParentModule.sysAdmin,
    dynamicSubMenus: [],
  },
});

export function getAppMainMenuFromRouterModule(
  fromRouter: RouterParentModule
): AppMainMenu | undefined {
  if (!fromRouter) return undefined;

  const modulesMapped: any = {
    [RouterParentModule.home]: AppMainMenu.Home,
    [RouterParentModule.askBuddy]: AppMainMenu.AskBuddy,
    [RouterParentModule.communicationAI]: AppMainMenu.Comm,
    [RouterParentModule.responseAI]: AppMainMenu.Resp,
    [RouterParentModule.nps]: AppMainMenu.Nps,
    [RouterParentModule.socialMedia]: AppMainMenu.SocialMedia,
    [RouterParentModule.marketAI]: AppMainMenu.MarketPlace,
    [RouterParentModule.profitEdge]: AppMainMenu.ProfitEdge,
    [RouterParentModule.insight]: AppMainMenu.Insight,
    [RouterParentModule.clientAdmin]: AppMainMenu.ClientAdmin,
    [RouterParentModule.centriqeAdmin]: AppMainMenu.CentriqeAdmin,
    [RouterParentModule.sysAdmin]: AppMainMenu.SystemAdmin,
    [RouterParentModule.visionEdge]: AppMainMenu.VisionEdge,
    [RouterParentModule.srq]: AppMainMenu.SRQ,
    [RouterParentModule.customerData]: AppMainMenu.CustomerData,
    [RouterParentModule.engageAi]: AppMainMenu.EngageAI,
  };

  return modulesMapped[fromRouter];
}

export enum HTMLInputType {
  text = 'text',
  number = 'number',
  date = 'date',
  datetimeLocal = 'datetime-local',
  time = 'time',
  file = 'file',
  image = 'image',
  password = 'password',
  email = 'email',
}

export type HTMLInput =
  | 'text'
  | 'number'
  | 'date'
  | 'datetime-local'
  | 'time'
  | 'file'
  | 'image'
  | 'password'
  | 'email'
  | 'tel';

export function getStatusMessageString(status: EntityStatusCode): string {
  if (!status) return 'unknown!';

  return status === EntityStatusCode.InDraft ? 'draft' : status.toLowerCase();
}

export enum AppEventsSource {
  commAppEvents = 'commAppEvents',
  npsAppEvents = 'npsAppEvents',
  respAppEvents = 'respAppEvents',
  msgCreateEvents = 'msgCreateEvents',
  msgSendEvents = 'msgSendEvents',
  interactiveEvents = 'interactiveEvents',
}
