/** 18112020 - Gaurav - Init version: A module for all shared components
 * 08022021 - Gaurav - Added Loading component
 * 16022021 - Gaurav - Added TrustResourceUrlPipe pipe
 * 22042021 - Abhishek - JIRA-CA-388: Update user configuration based on new security > Add org tags to holding org
 * 01122021 - Gaurav - Added ContentLoaderModule package and a custom skeleton for setup-list style in our app
 * 10062022 - Gaurav - New arc rotating spinner
 * 06072022 - Gaurav - ArrayToStringPipe
 * 12072022 - Gaurav - CA-1311: ConvertTimeToLocalePipe
 * 15072022 - Gaurav - CA-1333: Open header menu on hover
 * 08092022 - Gaurav - CA-1473: Show offline and slow network status info in header
 * 19102022 - Gaurav - Loading section component and array copy pipe
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/** Angular Material Modules */
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';

/** Custome Components */
import { ProgressBarComponent } from './components/progress-bar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TrustResourceUrlPipe } from './pipes/trust-resource-url.pipe';
import { FilterFormControlArrayPipe } from './pipes/filter-form-control-array.pipe';
import { BaseComponent } from './base/base.component';
import { FileSizePipe } from './pipes/file-size.pipe';

/** Directives */
import { ResizeDirective } from './directives/resize.directive';
import { DynamicHeightDirective } from './directives/dynamic-height.directive';
import { ExpandPanelMultiDirective } from './directives/expand-panel-multi.directive';
import { FocusInvalidFieldDirective } from './directives/focus-invalid-field.directive';
import { FormControlInvalidDirective } from './directives/form-control-invalid.directive';
import { HighlightFormFieldDirective } from './directives/highlight-form-field.directive';
import { RequiredColorDirective } from './directives/required-color.directive';
import { DelayTypingActionDirective } from './directives/delay-typing-action.directive';
import { DelaySearchFieldComponent } from './components/delay-search-field/delay-search-field.component';

import { ContentLoaderModule } from '@ngneat/content-loader';
import { SetupListSkeletonComponent } from './components/skeletons/setup-list-skeleton/setup-list-skeleton.component';
import { CrudPageSkeletonComponent } from './components/skeletons/crud-page-skeleton/crud-page-skeleton.component';
import { SpinnerArcComponent } from './components/spinner/spinner-arc/spinner-arc.component';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';
import { ConvertTimeToLocalePipe } from './pipes/convert-time-to-locale.pipe';
import { DropDownMenuOpenDirective } from './directives/drop-down-menu-open.directive';
import { DropDownMenuToggleDirective } from './directives/drop-down-menu-toggle.directive';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { LabelRequiredAsteriskDirective } from './directives/label-required-asterisk.directive';
import { AlertComponent } from './components/alert/alert.component';
import { ShowTableVscrollDirective } from './directives/show-table-vscroll.directive';
import { NetworkInfoComponent } from './components/network-info/network-info.component';
import { PreventHrefActionDirective } from './directives/prevent-href-action.directive';
import { OnHoverDirective } from './directives/on-hover.directive';
import { DynamicDirective } from './directives/dynamic.directive';
import { CopyArrayPipe } from './pipes/copy-array.pipe';
import { LoadingSectionComponent } from './components/loading-section/loading-section.component';
import { RouterLinkActiveExtensionDirective } from './directives/router-link-active-extension.directive';
import { ObserveMutationDirective } from './directives/observe-mutation.directive';

@NgModule({
  declarations: [
    ProgressBarComponent,
    LoadingComponent,
    SpinnerComponent,
    TrustResourceUrlPipe,
    FilterFormControlArrayPipe,
    BaseComponent,
    FileSizePipe,
    ResizeDirective,
    DynamicHeightDirective,
    RequiredColorDirective,
    FocusInvalidFieldDirective,
    HighlightFormFieldDirective,
    FormControlInvalidDirective,
    ExpandPanelMultiDirective,
    DelayTypingActionDirective,
    DelaySearchFieldComponent,
    SetupListSkeletonComponent,
    CrudPageSkeletonComponent,
    SpinnerArcComponent,
    ArrayToStringPipe,
    ConvertTimeToLocalePipe,
    DropDownMenuOpenDirective,
    DropDownMenuToggleDirective,
    FilterArrayPipe,
    LabelRequiredAsteriskDirective,
    AlertComponent,
    ShowTableVscrollDirective,
    NetworkInfoComponent,
    PreventHrefActionDirective,
    OnHoverDirective,
    DynamicDirective,
    CopyArrayPipe,
    LoadingSectionComponent,
    RouterLinkActiveExtensionDirective,
    ObserveMutationDirective,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    ContentLoaderModule,
  ],
  exports: [
    /** Modules */
    CommonModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ContentLoaderModule,

    /** Components */
    ProgressBarComponent,
    LoadingComponent,
    SpinnerComponent,
    DelaySearchFieldComponent,
    SetupListSkeletonComponent,
    CrudPageSkeletonComponent,
    SpinnerArcComponent,
    AlertComponent,
    NetworkInfoComponent,
    LoadingSectionComponent,

    /** Pipes */
    TrustResourceUrlPipe,
    FilterFormControlArrayPipe,
    FileSizePipe,
    ArrayToStringPipe,
    ConvertTimeToLocalePipe,
    FilterArrayPipe,
    CopyArrayPipe,

    /** Directives */
    ResizeDirective,
    DynamicHeightDirective,
    RequiredColorDirective,
    FocusInvalidFieldDirective,
    HighlightFormFieldDirective,
    FormControlInvalidDirective,
    ExpandPanelMultiDirective,
    DelayTypingActionDirective,
    DropDownMenuOpenDirective,
    DropDownMenuToggleDirective,
    LabelRequiredAsteriskDirective,
    ShowTableVscrollDirective,
    PreventHrefActionDirective,
    OnHoverDirective,
    DynamicDirective,
    RouterLinkActiveExtensionDirective,
    ObserveMutationDirective,
  ],
})
export class SharedModule {}
