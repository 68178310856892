/** 18112020 - Gaurav - Init version: First component to load on starting the web app,
 * control access to the Centriqe app.
 * 21052021 - Gaurav - Darn! Someone had a snackbar here, although they're supposed to have in http-interceptor area!
 * Removed snackbar for http-response errors which override the interceptor snackbar and showed "[Object: ProgressEvent]"
 * 08072021 - Gaurav - CA-645: System Admin: Implement new button behaviour for missing required fields
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 * 18072022 - Gaurav - CA-1336: Add background to login page
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

/** Get Services */
import { AuthService } from '../auth.service';

/** Get UI Components */
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarService } from 'src/app/shared/components/snackbar.service';
import { Observable } from 'rxjs';
import { FormInvalidProps, LoginRequestProp } from 'src/app/core/models/auth';
import {
  kDefaultFormInvalidProps,
  kFocusFormInvalidProps,
} from 'src/app/core/models/app-constants';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  isLoading = true;

  private _random = Math.floor(Math.random() * 9) + 1;
  private _imageNumber =
    this._random > 9 || this._random < 1 ? 1 : this._random;

  private _attributionText: { [key: number]: string } = {
    1: 'Ai technology vector created by rawpixel.com - www.freepik.com',
    2: 'Optimization vector created by upklyak - www.freepik.com',
    3: 'Robot hand photo created by rawpixel.com - www.freepik.com',
    4: 'Data background vector created by starline - www.freepik.com',
    5: 'Ai face vector created by starline - www.freepik.com',
    6: 'Brain technology photo created by rawpixel.com - www.freepik.com',
    7: 'Robot hand photo created by rawpixel.com - www.freepik.com',
    8: 'Digital lines vector created by starline - www.freepik.com',
    9: 'Electronic circuit vector created by liuzishan - www.freepik.com',
  };

  /** Forgot User ID / Password: MatExpansionPanel related code props */
  forgotPanelOpenState = false;
  @ViewChild(MatExpansionPanel) forgotPanel!: MatExpansionPanel;

  hide = true;
  isResetPasswordOnLogon: boolean = false;
  isDisableForgotSubmitButton: boolean = false;
  isDisableLoginSubmitButton: boolean = false;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _formService: FormService
  ) { }

  get isFormInvalid(): Observable<FormInvalidProps> {
    return this._formService.isFormInvalid;
  }

  set formInvalid(props: FormInvalidProps) {
    this._formService.setFormInvalidity(props);
  }

  get getRandomImageNumber(): number {
    // this._imageNumber = Math.floor(Math.random() * 10) + 1;
    return this._imageNumber;
  }

  get getFreepikAttributionText(): string {
    return this._attributionText[this._imageNumber];
  }

  ngOnInit(): void {
    this.isLoading = false;
    this._authService.getAuthStatusListener().subscribe((_) => {
      this.isDisableLoginSubmitButton = false;
    });
  }

  onLogin(formLogin: NgForm) {
    this.formInvalid = kDefaultFormInvalidProps;

    if (!formLogin.valid) {
      this.formInvalid = kFocusFormInvalidProps;
      return;
    }

    console.log('onLogin', { formLogin });

    this.isLoading = true;
    /** http subs are unsubscribed automaticall by angular, so not unsubscribing it here */
    this._authService
      .login(<LoginRequestProp>{
        userId: formLogin.value.username,
        password: formLogin.value.password,
      })
      .subscribe(
        (response) => {
          this.isDisableLoginSubmitButton = true;
          this.isResetPasswordOnLogon = response.isResetPasswordOnLogon;
          if (this.isResetPasswordOnLogon) {
            this._router.navigate(['/changePassword']);
          }
          this._router.navigate(['/dashboard']);
          this.isLoading = false;
        },
        (_) => {
          this.isLoading = false;
          // Clear any cache set
          this._authService.logout();
        }
      );
  }

  onForgotCredentials(formForgot: NgForm, formLogin: NgForm) {
    this.formInvalid = kDefaultFormInvalidProps;

    if (!formForgot.valid) {
      this.formInvalid = kFocusFormInvalidProps;
      return;
    }

    formLogin.controls['password'].setValue('');

    this._authService
      .forgotPassword(formForgot.value.email)
      .then((res) => {
        this._snackbarService.showSuccess(res?.message ?? 'Request sent successfully.');
        this.isDisableForgotSubmitButton = true;
        formForgot.control.controls.email.disable;
      })
      .catch((_) => { });
  }

  closePanel() {
    /** Close the forgot feature panel if user navigates away to click on the login form,
     * only if it is already open */
    if (this.forgotPanel.expanded) this.forgotPanel.close();
  }
}
