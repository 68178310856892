/** 18112020 - Gaurav - Init version
 * 19112020 - Gaurav - Added check for local user session check on each 'page' refresh
 * 09042021 - Gaurav - Check whether ngsw.json file to auto-reload app to use new sw version
 * 02092022 - Gaurav - Show spinner when lazy chunks are being loaded
 * 15092022 - Gaurav - Fixed show loading spinner on lazy load modules
 * 21092022 - Gaurav - CA-1501: Prototype for 'onboarding steps'
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { LocalStorageService } from './local-storage.service';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private _routerEventsSub!: Subscription;

  constructor(
    private _authService: AuthService,
    private _swUpdate: SwUpdate,
    private _router: Router,
    private _loadingService: LoadingService,
    private _localStorageService: LocalStorageService
  ) {
    /** 08032021 - Gaurav - JIRA-CA-231: Disallow console.log to seep in prod environment, allow only when env variable (allowConsoleLogs === true) */
    if (!environment?.allowConsoleLogs) {
      if (window) {
        window.console.table =
          window.console.log =
          window.console.warn =
          window.console.info =
          window.console.dir =
            function () {
              // Don't log anything.
            };
      }
    }

    _localStorageService.initTestKeys();
  }

  ngOnInit() {
    this._routerEventsSub = this._router.events
      .pipe(
        filter(
          (e: any) =>
            e instanceof RouteConfigLoadStart || e instanceof RouteConfigLoadEnd
        )
      )
      .subscribe((event: RouterEvent) => {
        //lazyloading start
        if (event instanceof RouteConfigLoadStart) {
          console.log('AppComponent : events sub : loading chunck', { event });
          this._loadingService.showSpinner(true);
        }
        //lazy loading end
        else if (event instanceof RouteConfigLoadEnd) {
          this._loadingService.showSpinner(false);
        }
      });

    /** Check cache for user session and login or logout user */
    this._authService.autoLogin();

    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe(() => {
        // if (
        //   confirm('New version available of Centriqe app. Load New Version?')
        // ) {
        // auto-reload app when a new service worker version is available
        window.location.reload();
        // }
      });
    }
  }

  ngOnDestroy(): void {
    this._routerEventsSub?.unsubscribe();
  }
}
