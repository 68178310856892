/** 24032022 - Gaurav - Store all app constants here
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 * 19052022 - Gaurav - CA-1220: added constant kBackendUrl
 * 26052022 - Gaurav - CA-1237: Implement list screen for Observations
 * 14062022 - Gaurav - CA-1266: kNoLineTypeChangedDefault
 * 09092022 - Gaurav - CA-1480: Create env variable for the number of the failed APIs to store
 * 19092022 - Frank - CA-1406: First steps toward refresh token in cookie
 * 21092022 - Gaurav - CA-1501: Prototype for 'onboarding steps'
 * 21092022 - Gaurav - CA-1502: Move local storage actions to singleton LocalStorageService
 * 22092022 - Gaurav - CA-1505: Added excel download process flag
 * 28092022 - Gaurav - CA-1512: Create download service
 * 12122022 - Gaurav - CA-1606: Redesign Response AI menu
 * 23012023 - Gaurav - CA-1686: Move system admin to avatar menu
 * 16022023 - Gaurav - CA-1714: Add SimulatorTypeCode to Observation and Recommendation
 */

import { environment } from 'src/environments/environment';
import { ListResultDto } from '../dto/base.dto.ts';
import { ExcelDownloadProcess } from './app-types';
import { FormInvalidProps } from './auth';
import { LineTypeChangeProp } from './client-admin.model';
import { SearchByCriteriaPostData } from './common.model';

export const kAppDefaultDateFormat = 'YYYY-MM-dd HH:mm';
export const kAppDefaultDateOnlyFormat = 'YYYY-MM-dd';

export const kDefaultFormInvalidProps = <FormInvalidProps>{
  isControlInvalid: false,
  isFormSubmit: false,
};
export const kFocusFormInvalidProps = <FormInvalidProps>{
  isControlInvalid: true,
  isFormSubmit: true,
};

export const kBackendUrl = `${environment.apiUrlV1}/sql`;
export const kApiUrl = `${environment.apiUrlV1}`; //sans sql prefix for all new enhancements
export const kBackendAuthUrl = `${kBackendUrl}${
  environment.auth.authPath ?? ''
}`;
export const kNoOfFailedApisToLog =
  (environment as any)?.nOfFailedApisToLog ?? 10;
export const kDownloadManagerLogLimit = 20;
export const kCentriqeAdministratorEmail = 'admin@centriqe.com';

export const kEmptyListResultDto: ListResultDto = {
  info: { limit: 0, offset: 0, resultCount: 0, totalCount: 0 },
  results: [],
};

export const kPageSizeLimit = 30;
export const kPageSizeOptions = [10, 20, 30, 50, 70];
export const kDefaultSearchCriteriaForPost: SearchByCriteriaPostData = {
  options: {
    offset: 0,
    limit: kPageSizeLimit,
    sort: 'updatedDt desc',
  },
};

export const kNoLineTypeChangedDefault: LineTypeChangeProp = {
  changedValue: false,
  newLineType: null,
  newLineTypeId: null,
  nodeId: null,
  sortOrder: null,
};

export const kAssignToHoldingOrg = 'Assign to Holding Org';

export const kTemplateMaxLength = 100;
export const kSmsTextMaxLength = 800;
export const kWhatsAppTextMaxLength = 2000;
export const kEmailSubjectMaxLength = 200;
export const kMediaUrlMaxLength = 500;

export const kCodeFieldMaxLength = 20;

export const kShowSubHeaderAtScrollPos = 170; //17102022 - Gaurav - CA-1559: Show List page header information on page scroll

// storage keys
export const kGuidedTourKey = 'gT_iaxgVArpWOP7iiZ7SQUmbw';
export const kOnboardingKey = 'oB_kP0w2ZQyJdll5e8Yx8l1IA==';
export const kOnboardStepKey = 'oBS_Qy7bFFTZXI9MXqzlmbqe0Q==';

export const kServerErrorLogKey = 'errl0gkgpEDXw870LVj39w==';
export const kHoCuMo = 'hoCuMo';
export const kLoginDataKey = 'lOginU/kqaRHL2Y9Aog2cw==';
export const kCurrentNavigationDataKey = 'cND_navTjVCGYyPBz43By7tTvw==';
export const kLastAttemptedBrowserLocationKey = 'lABL_lstKm8VeBR0wSerIS6S2vg==';

export const kToggleUserSettingsMenuExpansionKey = 'uSMT_E1zLHhmkM4Y7wJaVSrPfw';
export const kLastFilteredHoldingOrgKey = 'lFHO_kGIMmNha3HpGZGnc4mw';

export const kExcelDownloadProcess: ExcelDownloadProcess =
  (environment as any)?.excelDownloadProcess ?? 'background';

export const kFileDownloadStoreKey = 'fDWNLD_3NzowBKwTLT2a/x6VjB98Q==';
export const kToggleSideMenuExpansionKey = 'sm_mXvdhzESCOLOE6AH7BsbdQ==';

export const kLastSelectedDashConfigdHoldingOrgKey =
  'lSDCHO_1VO2ouvQUZzPD52u6HeYUQ==';

export const kLastSelectedRecommendationsSimulatorType =
  'lSRST_Ozjw1BNNvoOepENipXhPTw==';
export const kLastSelectedObservationsSimulatorType =
  'lSOST_8DTLOVYw8J0wc/PPJDWwRw==';
