/** 02072021 - Gaurav - CA-638: Implement new button behaviour and required fields display: create base components
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 * 11102022 - Gaurav - Remove 'padding-top' to avoid the control to shift-down below from the x-axis (in-line) with other controls
 * 11102022 - Gaurav - Added smooth transition for bgcolor and padding
 */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormInvalidProps } from 'src/app/core/models/auth';

@Directive({
  selector: '[appHighlightFormField]',
})
export class HighlightFormFieldDirective implements OnChanges {
  @Input() isCurrentFieldValueValid!: boolean | undefined;

  constructor(private _el: ElementRef, private _r2: Renderer2) {}

  private _processChange(highlight: boolean): void {
    // console.log('appHighlightFormField', {
    //   isCurrentFieldValueValid: this.isCurrentFieldValueValid,
    //   highlight,
    // });

    if (this.isCurrentFieldValueValid && !highlight) {
      this._r2.setStyle(this._el.nativeElement, 'border-left', 'none');
      this._r2.setStyle(this._el.nativeElement, 'background-color', 'inherit');
      this._r2.setStyle(this._el.nativeElement, 'padding', '0px');
      // this._r2.setStyle(this._el.nativeElement, 'padding-top', '9px');
      this._r2.setStyle(this._el.nativeElement, 'border-radius', 'inherit');
      this._r2.setStyle(
        this._el.nativeElement,
        'transition',
        'background-color 0.5s, padding 0.25s'
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('appHighlightFormField', { changes });
    this._processChange(changes?.appHighlightFormField?.currentValue);
  }

  @Input() set appHighlightFormField(highlight: FormInvalidProps | null) {
    // console.log('appHighlightFormField', {
    //   isCurrentFieldValueValid: this.isCurrentFieldValueValid,
    //   highlight,
    // });
  }
}
