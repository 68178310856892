/** 21092022 - Gaurav - CA-1501: New central service for Local Storage */

import { Injectable } from '@angular/core';
import {
  kGuidedTourKey,
  kOnboardingKey,
  kOnboardStepKey,
} from './core/models/app-constants';

@Injectable()
export class LocalStorageService {
  initTestKeys(): void {
    // Check for guided-tour key
    if (localStorage.getItem(kGuidedTourKey) === null) {
      this.setKey(kGuidedTourKey, false);
    }

    // Check for onboarding key
    if (localStorage.getItem(kOnboardingKey) === null) {
      this.setKey(kOnboardingKey, false);
    }

    if (localStorage.getItem(kOnboardStepKey) === null) {
      this.setKey(kOnboardStepKey, 0);
    }
  }

  setKey(key: string, value: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('LocalStorageService : setKey', { error, key, value });
    }
  }

  removeKey(key: string): void {
    localStorage.removeItem(key);
  }

  getKey(key: string, returnValForNoneFound: any = null): any {
    return JSON.parse(
      localStorage.getItem(key) ?? JSON.stringify(returnValForNoneFound)
    );
  }
}
