/** 18112020 - Gaurav - Init version: Service for Authentication related actions in the app
 * 16032021 - Gaurav - JIRA-CA-237: User is forced to dashboard 'home' page on page refresh, fixed to load last navigation before refresh
 * 06042021 - Gaurav - JIRA-CA-340: Show offline status of user in the header
 * 23042021 - Gaurav - JIRA-CA-402: Implement double login for now, to work parallelly on the new login routes. Added LoginData and related interfaces
 * 27042021 - Gaurav - JIRA-CA-397: Switch UI over to use new login
 * 30042021 - Gaurav - JIRA-CA-457: Child menu gets unselected on org change
 * 08072021 - Gaurav - CA-645: Moved isFormInvalid from dashboard service to here
 * 23032022 - Gaurav - CA-1118: Override saved navigation when browser or tab opens for a clicked link
 * 23032022 - Gaurav - CA-1119: Open clicked link after successful user logon
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 * 17062022 - Gaurav - CA-1287: Clear app caches on logout since a normal user may log after an admin
 * 08092022 - Gaurav - CA-1473: Show offline and slow network status info in header
 * 16092022 - Frank - Testing cookie settings -- sending "withCredentials" during login -- and reverted again
 * 19092022 - Frank - CA-1406 - Moved switchOrg to follow login route path
 * 21092022 - Gaurav - CA-1501: Prototype for 'onboarding steps'
 * 21092022 - Gaurav - CA-1502: Move local storage actions to singleton LocalStorageService
 * 26092022 - Frank - Added missing withCredentials to switchOrg
 * 11102022 - Gaurav - CA-1548: Move form validity observable from Auth Service to new Form Service
 * 14122022 - Gaurav - CA-1645: Remove now obsolete menu code and fix storage of last used route
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Observer,
  of,
  Subject,
} from 'rxjs';
import { map, mergeAll, tap } from 'rxjs/operators';

import {
  LoginData,
  LoginRequestProp,
  LoginUserData,
  OrgSelectorListData,
  SelectedCustomerMemberOrg,
} from '../core/models/auth';
import { OrgSelectorResponse } from '../dashboard/shared/components/dialog/dialog.model';
import { UserType } from '../core/models/user';
import { Location } from '@angular/common';
import { RouterParentModule } from '../core/models/common.model';
import { DataCacheService } from '../dashboard/shared/services/data-cache.service';
import {
  kBackendAuthUrl,
  kBackendUrl,
  kCurrentNavigationDataKey,
  kHoCuMo,
  kLastAttemptedBrowserLocationKey,
  kLoginDataKey,
  kOnboardingKey,
} from '../core/models/app-constants';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';

export interface AuthStatusData {
  isAuthenticated: boolean;
  isResetPasswordOnLogon: boolean;
  loginData: LoginData | null;
}

export interface NavigationData {
  commands: any[];
  extras?: NavigationExtras | undefined;
}

const withCredentials = environment.auth.withCredentials ?? true;

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _isUserAuthenticated = false;

  private _authStatusListener = new BehaviorSubject<AuthStatusData>({
    isAuthenticated: false,
    isResetPasswordOnLogon: false,
    loginData: null,
  });

  private _orgSwitchListener = new Subject<boolean>();
  orgSwitchObs$ = this._orgSwitchListener.asObservable();

  private _tokenTimer: any;
  isResetPasswordOnLogon: boolean = false;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private location: Location,
    private _dataCacheService: DataCacheService,
    private _localStorageService: LocalStorageService
  ) {}

  get isOnboardingProcess(): boolean {
    return this._localStorageService.getKey(kOnboardingKey, false);
  }

  isOnline$() {
    return of(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    ).pipe(mergeAll());
  }

  connectionChangeObs$() {
    return of(
      new Observable((observer: Observer<string>) => {
        const effectiveType = (navigator as any)?.connection?.effectiveType;
        // const type = (navigator as any)?.connection?.type;

        // console.log({ effectiveType, type });
        observer.next(effectiveType);

        const onConnectionChange = () => {
          const effectiveType = (navigator as any)?.connection?.effectiveType;
          // const type = (navigator as any)?.connection?.type;
          // console.log({ effectiveType, type });
          observer.next(effectiveType);
        };

        (navigator as any)?.connection?.addEventListener(
          'change',
          onConnectionChange
        );

        return () => {
          (navigator as any)?.connection?.removeEventListener(
            'change',
            onConnectionChange
          );
          observer.complete();
        };
      })
    ).pipe(mergeAll());
  }

  getAuthStatusListener() {
    /** Return a copy of the observable to avoid any next() calls from outside here */
    return this._authStatusListener.asObservable();
  }

  getCurrentUserData(): AuthStatusData {
    return this._authStatusListener.getValue();
  }

  checkIfToResetPasswordOnNextLogon(): boolean {
    const loginData = this._localStorageService.getKey(kLoginDataKey);

    let isResetPasswordOnLogon = false;
    if (!!loginData) {
      isResetPasswordOnLogon = loginData?.user?.resetPasswordNextLogon;
    }
    return isResetPasswordOnLogon;
  }

  isUserAuthenticated(): boolean {
    return this._isUserAuthenticated;
  }

  getHoldingOrgSelectors(): Observable<OrgSelectorListData[]> {
    return this._http.get<OrgSelectorListData[]>(
      `${kBackendUrl}/holdingOrgs/selector`
    );
  }

  switchOrg(
    payload: OrgSelectorResponse,
    currentAuthData: AuthStatusData
  ): Observable<LoginData> {
    return this._http
      .post<LoginData>(`${kBackendAuthUrl}/switchOrg`, payload, {
        withCredentials,
      })
      .pipe(
        tap((response) => {
          if (!!response) {
            response.user = <LoginUserData>{
              ...response.user,
              isAdmin:
                response.user?.userType === UserType.SystemAdmin ||
                response.user?.userType === UserType.CentriqeAdmin,
            };

            /** Use JS atob() instead of 3rd party decoder to decode JWT token */
            const parsedToken = JSON.parse(atob(response.token.split('.')[1]));

            /** Set auto-logoff timer based on token expiry seconds */
            this._setAuthTimer(parsedToken.exp);

            this._updateAuthStatusListener({
              ...currentAuthData,
              loginData: response,
            });

            this._orgSwitchListener.next(true);
          }
        })
      );
  }

  login(loginProps: LoginRequestProp) {
    const holdingOrgCode = this.getCodeFromUrl();
    if (!!holdingOrgCode && holdingOrgCode !== '') {
      loginProps = {
        ...loginProps,
        holdingOrgCode,
      };
    }

    return this._http
      .post<LoginData>(`${kBackendAuthUrl}/login`, loginProps, {
        withCredentials,
      })
      .pipe(
        /** Expected to get token always from the API on success */
        map((loginData: LoginData) => {
          console.log({ loginData });

          const token = loginData.token;
          loginData.user = <LoginUserData>{
            ...loginData.user,
            isAdmin:
              loginData.user?.userType === UserType.SystemAdmin ||
              loginData.user?.userType === UserType.CentriqeAdmin,
          };

          this._isUserAuthenticated = true;
          /** Use JS atob() instead of 3rd party decoder to decode JWT token */
          const parsedToken = JSON.parse(atob(token.split('.')[1]));

          /** Set auto-logoff timer based on token expiry seconds */
          this._setAuthTimer(parsedToken.exp);

          this.isResetPasswordOnLogon =
            loginData.user.resetPasswordNextLogon || false;

          this._updateAuthStatusListener({
            loginData,
            isResetPasswordOnLogon: this.isResetPasswordOnLogon,
            isAuthenticated: this._isUserAuthenticated,
          });

          if (!!this.isOnboardingProcess) {
            this._router.navigate([
              `dashboard/${RouterParentModule.onboarding}`,
            ]);
            return { isResetPasswordOnLogon: this.isResetPasswordOnLogon };
          }

          /** 23032022 - Gaurav - CA-1119: Open clicked link after successful user logon */
          const lastAttemptedBrowserLocation =
            this._getLastAttemptedBrowserLink();

          if (
            !!lastAttemptedBrowserLocation &&
            lastAttemptedBrowserLocation !== ''
          ) {
            setTimeout(() => {
              window.location.href = lastAttemptedBrowserLocation;
              this._setLastAttemptedBrowserLink('');
            }, 0);
          }

          return { isResetPasswordOnLogon: this.isResetPasswordOnLogon };
        })
      );
  }

  private _updateAuthStatusListener(authStatusData: AuthStatusData): void {
    /** Update observers */
    this._authStatusListener.next(authStatusData);

    /** Save auth data in local storage till token expiry, and to persis user session */
    this._saveAuthData(authStatusData);
  }

  forgotPassword(email: string) {
    return this._http
      .post<{ message: string }>(`${kBackendUrl}/forgotPassword`, {
        email,
        holdOrgCode: this.getCodeFromUrl(),
      })
      .toPromise();
  }

  logout(checkLastAttemptedBrowserLocation: boolean = false): void {
    /** 23032022 - Gaurav - CA-1119: Open clicked link after successful user logon */
    if (checkLastAttemptedBrowserLocation) {
      const lastAttemptedBrowserLocation = window.location.href;
      console.log('logout', {
        lastAttemptedBrowserLocation,
        includes: lastAttemptedBrowserLocation.includes('dashboard'),
      });

      this._setLastAttemptedBrowserLink(
        lastAttemptedBrowserLocation.includes('dashboard')
          ? lastAttemptedBrowserLocation
          : ''
      );
    }

    this._cleanUp();
    this._isUserAuthenticated = false;
    this._authStatusListener.next({
      isAuthenticated: this._isUserAuthenticated,
      isResetPasswordOnLogon: false,
      loginData: null,
    });

    this._router.navigate(['/login']);
  }

  resetPassword(payload: { password: string }) {
    return this._http
      .post<{ message: string }>(`${kBackendUrl}/updatePassword`, payload)
      .toPromise();
  }

  /** Called in App Component */
  autoLogin(): void {
    const authInformation = this._getAuthData();
    // No token found in localStorage cache
    if (!authInformation || !authInformation.loginData) {
      this.logout(true);
      return;
    }

    const loginData = authInformation.loginData;
    const parsedToken = JSON.parse(atob(loginData.token.split('.')[1]));
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(parsedToken.exp);
    const expiresIn = expirationDate.getTime();
    const now = new Date().getTime();

    /** If the token is yet to expire:
     * 1. set user info in JS memory,
     * 2. reset the timer to the remaining seconds, and
     * 3. update observers!
     *
     * else logout */
    if (expiresIn > now) {
      this._isUserAuthenticated = true;
      this._setAuthTimer(parsedToken.exp);
      this._authStatusListener.next({
        isAuthenticated: this._isUserAuthenticated,
        isResetPasswordOnLogon: false,
        loginData,
      });

      if (!!this.isOnboardingProcess) {
        this._router.navigate([`dashboard/${RouterParentModule.onboarding}`]);
        return;
      }

      /** 16032021 - Gaurav - JIRA-CA-237 */
      const currentNavigationData: NavigationData = this.getCurrentNavigation();

      /** 23032022 - Gaurav - CA-1118: Override saved navigation when browser or tab opens for a clicked link */
      const locationPath = this.location.path();
      let dashUrlFromBrowser = '';

      if (!!locationPath && locationPath !== '') {
        dashUrlFromBrowser = locationPath.slice(1); //remove fwd slash
      }

      const dashUrlFromStoredNavigationData =
        currentNavigationData.commands.join('/');

      console.log('authService: autoLogin', {
        currentNavigationData,
        'window.location.href': window.location.href,
        dashUrlFromBrowser,
        dashUrlFromStoredNavigationData,
        location_path: this.location.path(),
      });

      if (
        dashUrlFromBrowser !== '' &&
        dashUrlFromBrowser !== dashUrlFromStoredNavigationData
      ) {
        /** take natural route to window.location.href */
      } else {
        /** Navigate to saved Route */
        this._router.navigate(
          currentNavigationData.commands,
          currentNavigationData?.extras &&
            Object.entries(currentNavigationData?.extras).length > 0
            ? {
                ...currentNavigationData.extras,
              }
            : undefined
        );
      }

      /** 16032021 - Gaurav - JIRA-CA-237 - Ends */

      this._setLastAttemptedBrowserLink('');
    } else {
      this.logout();
    }
  }

  /** 16032021 - Gaurav - JIRA-CA-237 */
  public setCurrentNavigation(navigationData: NavigationData): void {
    this._localStorageService.setKey(kCurrentNavigationDataKey, navigationData);
  }

  public getCurrentNavigation(): NavigationData {
    const currentNavigationData = this._localStorageService.getKey(
      kCurrentNavigationDataKey
    );

    if (!currentNavigationData) {
      // default to dashboard
      return <NavigationData>{
        commands: ['/dashboard'],
        selectedMenuLevels: {
          selectedMenu: 0,
          selectedChildMenu: 0,
        },
      };
    }

    return currentNavigationData;
  }
  /** 16032021 - Gaurav - JIRA-CA-237 - Ends */

  /** 28042021 - Gaurav - JIRA-CA-414: Customer org selector */
  public setSelectedCustomerMemberOrg(data?: SelectedCustomerMemberOrg): void {
    if (data) {
      this._localStorageService.setKey(kHoCuMo, data);
    } else {
      this._localStorageService.removeKey(kHoCuMo);
    }
  }

  public getSelectedCustomerMemberOrg(): SelectedCustomerMemberOrg | null {
    const selectedCustomerMemberOrg = this._localStorageService.getKey(kHoCuMo);
    if (!selectedCustomerMemberOrg) return null;

    return selectedCustomerMemberOrg;
  }
  /** 28042021 - Gaurav - JIRA-CA-414: Customer org selector - Ends */

  private _setAuthTimer(seconds: number) {
    this._tokenTimer = setTimeout(() => {
      this.logout();
    }, seconds);
  }

  private _saveAuthData(authStatusData: AuthStatusData): void {
    this._localStorageService.setKey(kLoginDataKey, authStatusData?.loginData);
  }

  private _getAuthData() {
    return {
      loginData: this._localStorageService.getKey(kLoginDataKey),
    };
  }

  private _setLastAttemptedBrowserLink(link: string): void {
    this._localStorageService.setKey(kLastAttemptedBrowserLocationKey, link);
  }

  private _getLastAttemptedBrowserLink(): string {
    return this._localStorageService.getKey(
      kLastAttemptedBrowserLocationKey,
      ''
    );
  }

  /** Clean-up before logout */
  private _cleanUp(): void {
    if (this._tokenTimer) clearTimeout(this._tokenTimer);
    this._localStorageService.removeKey(kLoginDataKey);
    this._localStorageService.removeKey(kHoCuMo);
    this._localStorageService.removeKey(kCurrentNavigationDataKey);
    this._dataCacheService.clearCachesOnLogout();
  }

  public getCodeFromUrl(): string {
    const hostName = location.hostname;
    let code = '';
    if (hostName === 'localhost') {
      code = '';
    }
    if (hostName && hostName !== 'localhost') {
      const indexOfDot = hostName.indexOf('.');
      code = hostName.substring(0, indexOfDot);
      if (code === 'app') {
        code = '';
      }
    }
    return code;
  }
}
