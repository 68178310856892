import { ExcelDownloadProcess } from 'src/app/core/models/app-types';
import { systemAdminRouteLinks } from 'src/app/dashboard/shared/components/menu/route-links/system-admin.routes';

export const environment = {
  production: true,
  apiUrlV1: 'https://api.dev.centriqe.com/api',
  ngMultiPurposeAppUrl: 'https://cust.dev.centriqe.com',
  allowConsoleLogs: true,
  nOfFailedApisToLog: 10,
  excelDownloadProcess: <ExcelDownloadProcess>'background',
  auth: {
    authPath: '',
    withCredentials: true,
  },
  featureFlags: {
    disabledPaths: [],
  },
};
