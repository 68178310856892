<mat-progress-bar
  mode="indeterminate"
  color="warn"
  class="height-3"
  *ngIf="isLoading"
></mat-progress-bar>

<div class="login-grand-container login-page">
  <div class="login-container">
    <div class="login-container-box">
 <mat-card appearance="outlined" [ngClass]="['mat-elevation-z5']">
    <form
      [formGroup]="resetPasswordForm"
      (ngSubmit)="changePassword(resetPasswordForm)"
    >

    <div class="logo">
        <img src="assets/img/holding_org.png" alt="Holding Org Logo" />
        <h2 class="password-message">Please change your password</h2>
      </div>
      <mat-divider class="logo-divider"></mat-divider>
       <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          name="password"
          formControlName="password"
          minlength="8"
          required
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          onchange="test(e)"
          type="button"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="resetPasswordForm.hasError('required', 'password')"
          >Please enter a valid password.</mat-error
        >
        <mat-error *ngIf="resetPasswordForm.controls['password']?.hasError('minlength')"
          >Must be greater or equal to 8 characters.</mat-error
        >
        <mat-error *ngIf="resetPasswordForm.controls['password']?.hasError('strong')"
        >Must contain a Lowercase, Uppercase, Special Character and Number.</mat-error
      >
      </mat-form-field>
      <mat-form-field>
        <mat-label> Confirm Password</mat-label>
        <input
          matInput
          type="text"
          name="confirmPassword"
          type="password"
          formControlName="confirmPassword"
          required
          [errorStateMatcher] = "matcher"
        />
        <mat-error  *ngIf="resetPasswordForm.controls['confirmPassword']?.touched &&  resetPasswordForm.hasError('notSame')"
          >Password doesn't match.</mat-error
        >

      </mat-form-field>

      <div class="submit-btn">
        <mat-divider></mat-divider>
        <button
          mat-raised-button
          color="accent"
          type="submit"
          *ngIf="!isLoading"
          [disabled]="!resetPasswordForm.valid || isLoading || isSubmitButtonDisabled"
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card>
</div>
</div>
</div>
