/** 05072021 - Gaurav - CA-638: Implement new button behaviour and required fields display: create base components
 * 18052022 - Gaurav - CA-1217: Fix auto-focus of required fields
 */
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInvalidProps } from 'src/app/core/models/auth';
import { FormService } from '../services/form.service';

@Directive({
  selector: '[appFormControlInvalid], [formControl], [formControlName]',
})
export class FormControlInvalidDirective {
  constructor(
    private _el: ElementRef,
    private _ctrl: NgControl,
    private _formService: FormService
  ) {}

  get control() {
    return this._ctrl.control;
  }

  @HostListener('keyup')
  @HostListener('selectionChange')
  @HostListener('click')
  onChangeValue() {
    // console.log('appFormControlInvalid', {
    //   control: this.control,
    //   invalid: this.control?.invalid,
    // });

    /** 18052022 - Gaurav - CA-1217: If control is invalid, mark it as touched to gain the highlights
     * 18072022 - Gaurav - Set control invalid only when it is dirty and invalid, parent Submit should take care to mark it as dirty if not already
     */
    if (!!this.control) {
      const isInvalid = !!this.control?.dirty && !!this.control?.invalid;

      this._formService.setFormInvalidity(<FormInvalidProps>{
        isControlInvalid: isInvalid,
      });
    }

    // console.log(
    //   this._el?.nativeElement?.ariaRequired,
    //   this._el?.nativeElement?.value
    // );
  }
}
