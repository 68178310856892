/** 19112020 - Gaurav - Init version: Error Interceptor Service to intercept incoming http response errors,
 * and show them in the app all from one place.
 * 16092021 - Gaurav - Modified to toe-in-line with the backend error response structure to check for errorMessage key.
 * HttpErrorResponse does not support this key so had to change return 'error' var to any
 * 14022022 - Gaurav - Merged centriqe-help from main, fixed conflicts
 * 12092022 - Gaurav - CA-1481: Store failed APIs information
 * 21092022 - Gaurav - CA-1502: Move local storage actions to singleton LocalStorageService
 * 28092022 - Gaurav - Added check for status 0 errors that caused when wifi connected but no internet; removed authstatus observable and used stored obs value method
 * 16122022 - Gaurav - CA-1650: Show logo left to avatar button along with org name */

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackbarService } from '../components/snackbar.service';
import { isNotEmpty } from '../util/common.util';
import { AuthService } from 'src/app/auth/auth.service';
import {
  kNoOfFailedApisToLog,
  kServerErrorLogKey,
} from 'src/app/core/models/app-constants';
import { ServerErrorLog } from 'src/app/core/models/system.model';
import { LocalStorageService } from 'src/app/local-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _snackbarService: SnackbarService,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /** catchError in the observable pipe before returning to the subscriber */
    return next.handle(req).pipe(
      catchError((error: any) => {
        /** 1. Get API error and
         * 2. Show a snackbar to the user for this error */

        let errorMessage =
          'Either the server may be down or restarting or you may be facing network issues, please refresh page after some time. If issue persists, please contact this webpage administrator or your network provider.';

        let showErrorMessage =
          isNotEmpty(error?.error?.errorMessage) ??
          isNotEmpty(error?.errorMessage) ??
          isNotEmpty(error?.error?.message) ??
          isNotEmpty(error?.message) ??
          errorMessage;

        !navigator.onLine &&
          (showErrorMessage =
            "You're offline! Please check your network connection and try again.");

        (!error || error?.status === 0) &&
          (showErrorMessage =
            'Your network connection may have some issues or the remote server may be busy! Please check and try again. If the issue persists, please email to Centriqe Administrator admin@centriqe.com');

        if (!!error) {
          const authStatus = this._authService.getCurrentUserData();

          const currentStoredFailedData = this._localStorageService.getKey(
            kServerErrorLogKey,
            []
          );

          const now = new Date();
          const errorLog: ServerErrorLog = {
            userInfo: !!authStatus?.loginData
              ? {
                  user: { ...authStatus.loginData.user },
                  org: {
                    holdingOrgId: authStatus.loginData.selectedOrg.holdingOrgId,
                    memberOrgId: authStatus.loginData.selectedOrg?.memberOrgId,
                    orgId: authStatus.loginData.selectedOrg.orgId,
                    uniqueOrgCode:
                      authStatus.loginData.selectedOrg.uniqueOrgCode,
                    holdingOrgCode:
                      authStatus.loginData.selectedOrg.holdingOrgCode,
                    holdingOrgName:
                      authStatus.loginData.selectedOrg.holdingOrgName,
                    holdingOrgLogoUrl:
                      authStatus.loginData.selectedOrg?.holdingOrgLogoUrl,
                    memberOrgCode:
                      authStatus.loginData.selectedOrg?.memberOrgCode,
                    memberOrgName:
                      authStatus.loginData.selectedOrg?.memberOrgName,
                    memberOrgLogoUrl:
                      authStatus.loginData.selectedOrg?.memberOrgLogoUrl,
                  },
                }
              : null,
            timeStamp: {
              local: now.toString(),
              iso: now.toISOString(),
              utc: now.toUTCString(),
            },
            route: window.location.href,
            request: {
              ...(req.method !== 'GET' && { payload: req.body }),
              method: req.method,
              responseType: req.responseType,
              url: req.url,
              urlWithParams: req.urlWithParams,
            },
            response: {
              message: error.message,
              name: error.name,
              ok: error.ok,
              status: error.status,
              statusText: error.statusText,
              url: error.url,
              error: error.error,
            },
            showErrorMessage,
          };

          if (
            !!errorLog.request?.payload &&
            errorLog.request.payload.hasOwnProperty('password')
          ) {
            delete errorLog.request.payload.password;
          }

          currentStoredFailedData.push(errorLog);

          if (currentStoredFailedData.length > kNoOfFailedApisToLog) {
            currentStoredFailedData.splice(0, 1);
          }

          this._localStorageService.setKey(
            kServerErrorLogKey,
            currentStoredFailedData
          );

          console.error('ErrorInterceptor', {
            errorLog,
            authStatus,
            currentStoredFailedData,
            kNoOfFailedApisToLog,
          });
        }

        console.error('ErrorInterceptor', {
          req,
          error,
        });

        this._snackbarService.showError(showErrorMessage);

        /** 16022022 - Gaurav - Logout user if unauthorized access. This can be made more specific in future */
        if (error?.status === 401) {
          this._authService.logout();
        }

        return throwError(error);
      })
    );
  }
}
