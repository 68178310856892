/** 19112020 - Gaurav - Init version: Auth Interceptor Service to intercept outgoing http requests to add auth token.
 * 24112020 - Gaurav - Added auth token in the header
 * 27042021 - Gaurav - JIRA-CA-397: Switch UI over to use new login
 * 28042021 - Gaurav - JIRA-CA-414: Customer org selector
 */

import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, map, exhaustMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this._authService.getAuthStatusListener().pipe(
      take(1),
      map((authStatus) => {
        return {
          isUserAuthenticated: authStatus.isAuthenticated,
          token: authStatus?.loginData?.token ?? '',
        };
      }),
      exhaustMap(({ isUserAuthenticated, token }) => {
        if (!isUserAuthenticated) return next.handle(req);

        let reqHeader;
        const hoCuMo = this._authService.getSelectedCustomerMemberOrg();
        if (hoCuMo) {
          reqHeader = {
            headers: req.headers
              .set('Authorization', `Bearer ${token}`)
              .set('x-auth-token', token)
              .set('hoCuMo', hoCuMo.memberOrgId),
          };
        } else {
          reqHeader = {
            headers: req.headers
              .set('Authorization', `Bearer ${token}`)
              .set('x-auth-token', token),
          };
        }

        const modifiedReq = req.clone({ ...reqHeader });

        return next.handle(modifiedReq);
      })
    );
  }
}
